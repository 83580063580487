/* .modal-xl {
    max-width: 90% !important;
}
*/

.modal-dialog {
    max-width: 80% !important;
} 


/* 
.custom-modal-dialog .modal-dialog {
    max-width: 90%;  /* Customize the dialog width
}
*/