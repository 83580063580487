.loading-container {
  --duration: 2s;
  /* --duration: 1.5s; */
  /* --container-size: 250px; */
  --container-size: 310px;
  --box-size: 33px;
  --box-border-radius: 15%;
  width: var(--container-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.loading-container .box {
  width: var(--box-size);
  height: var(--box-size);
  position: relative;
  display: block;
  transform-origin: -50% center;
  border-radius: var(--box-border-radius);
}

.loading-container .box::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  /* background-color: lightblue; */
  background-color: #ed7100; 
  border-radius: var(--box-border-radius);
  /* box-shadow: 0px 0px 10px 0px rgba(28, 159, 255, 0.4); */
  box-shadow: 0px 0px 10px 0px rgba(237, 113, 0, 0.4); 
}

.loading-container .box:nth-child(1) {
  animation: slide var(--duration) ease-in-out infinite alternate;
}

.loading-container .box:nth-child(1)::after {
  animation: color-change var(--duration) ease-in-out infinite alternate;
}

.loading-container .box:nth-child(2) {
  animation: flip-1 var(--duration) ease-in-out infinite alternate;
  animation-delay: 0.2s;
}

.loading-container .box:nth-child(2)::after {
  animation: squidge-1 var(--duration) ease-in-out infinite alternate;
  /* background-color: #1c9fff; */
  background-color: #ed7100; /* Naranja ajustado */
}

.loading-container .box:nth-child(3) {
  animation: flip-2 var(--duration) ease-in-out infinite alternate;
  animation-delay: 0.4s;
}

.loading-container .box:nth-child(3)::after {
  animation: squidge-2 var(--duration) ease-in-out infinite alternate;
  /* background-color: #1fb1fd; */
  background-color: #ff8f00; /* Color ligeramente más claro */
}

.loading-container .box:nth-child(4) {
  animation: flip-3 var(--duration) ease-in-out infinite alternate;
  animation-delay: 0.6s;
}

.loading-container .box:nth-child(4)::after {
  animation: squidge-3 var(--duration) ease-in-out infinite alternate;
  /* background-color: #22c7fb; */
  background-color: #ff9e30; /* Color ligeramente más claro */
}

.loading-container .box:nth-child(5) {
  animation: flip-4 var(--duration) ease-in-out infinite alternate;
  animation-delay: 0.8s;
}

.loading-container .box:nth-child(5)::after {
  animation: squidge-4 var(--duration) ease-in-out infinite alternate;
  /* background-color: #23d3fb; */
  background-color: #ffad57; /* Color ligeramente más claro */
}

@keyframes slide {
  0% {
    background-color: #ed7100;
    /* background-color: #1795ff; */
    transform: translateX(0vw);
  }
  100% {
    background-color: #ffb24d;
    /* background-color: #23d3fb; */
    transform: translateX(calc(var(--container-size) - (var(--box-size) * 1.25)));
  }
}

@keyframes color-change {
  0% {
    /* background-color: #1795ff; */
    background-color: #ed7100; /* Naranja ajustado */
  }
  100% {
    /* background-color: #23d3fb; */
    background-color: #ffb24d; /* Naranja más suave */
  }
}

@keyframes flip-1 {
  0%,
  15% {
    transform: rotate(0);
  }
  35%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes flip-2 {
  0%,
  15% {
    transform: rotate(0);
  }
  35%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes flip-3 {
  0%,
  15% {
    transform: rotate(0);
  }
  35%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes flip-4 {
  0%,
  15% {
    transform: rotate(0);
  }
  35%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-1 {
  5% {
    transform-origin: center bottom;
    transform: scaleX(1) scaleY(1);
  }
  15% {
    transform-origin: center bottom;
    transform: scaleX(1.3) scaleY(0.7);
  }
  25%,
  35% {
    transform-origin: center bottom;
    transform: scaleX(0.8) scaleY(1.4);
  }
  45%,
  100% {
    transform-origin: center top;
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes squidge-2 {
  5% {
    transform-origin: center bottom;
    transform: scaleX(1) scaleY(1);
  }
  15% {
    transform-origin: center bottom;
    transform: scaleX(1.3) scaleY(0.7);
  }
  25%,
  35% {
    transform-origin: center bottom;
    transform: scaleX(0.8) scaleY(1.4);
  }
  45%,
  100% {
    transform-origin: center top;
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes squidge-3 {
  5% {
    transform-origin: center bottom;
    transform: scaleX(1) scaleY(1);
  }
  15% {
    transform-origin: center bottom;
    transform: scaleX(1.3) scaleY(0.7);
  }
  25%,
  35% {
    transform-origin: center bottom;
    transform: scaleX(0.8) scaleY(1.4);
  }
  45%,
  100% {
    transform-origin: center top;
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes squidge-4 {
  5% {
    transform-origin: center bottom;
    transform: scaleX(1) scaleY(1);
  }
  15% {
    transform-origin: center bottom;
    transform: scaleX(1.3) scaleY(0.7);
  }
  25%,
  35% {
    transform-origin: center bottom;
    transform: scaleX(0.8) scaleY(1.4);
  }
  45%,
  100% {
    transform-origin: center top;
    transform: scaleX(1) scaleY(1);
  }
}

.loading-text {
    /* position: absolute; */
    font-size: 18px;
    /* color: #1795ff; */
    color: #ed7100; /* Naranja ajustado */
    font-weight: bold;
    text-transform: uppercase;
    animation: blinkText 1.5s ease-in-out infinite alternate;
  }
  
  @keyframes blinkText {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  
.loading-text-info {
  /* position: absolute; */
  font-size: 14px;
  /* color: #1795ff; */
  color: #ed7100; /* Naranja ajustado */
  font-weight: bold;
  text-transform: uppercase;
  animation: blinkText 1.5s ease-in-out infinite alternate;
}

@keyframes blinkText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}