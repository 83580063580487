.form-control, .form-select, .btn{
    border-radius: 10px!important;
}
input{
    border:1px solid  #999;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .btn-primary {
    color: #fff;
    background-color: #ed7100!important;
    border-color: #ed7100!important;
    padding: 8px 45px!important;
    border-radius: 10px!important;
    text-shadow:none!important;
    font-weight: bolder!important;
  }

  .btn-warning {
    color: #666668!important;
    font-weight: bolder!important;
}
.form-label{
  font-weight: bold;
  font-size: 0.9em;
}

.registro{
  background-image: url(../assets/images/Desbrozadorayhojas.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 430px;
}

.preview{
  display: block;
  cursor: pointer;
  border: 1px solid gray;
  height: 100px;
  width: 90px;
  text-align: center;
  padding-top: 25px;
  border-radius: 10%;
}

.profile{
  margin-bottom: 10px;

}

.text-muted{
  color: #842029!important;
}

@media screen and (max-width: 767px)  {
  .registro{
    background: none;
  }
}




