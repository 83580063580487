.download-reports {
  margin-top: 50px;
}

.fondo_blanco {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.titulo {
  font-weight: bold;
  margin-bottom: 20px;
}

.btn-block {
  width: 100%;
}