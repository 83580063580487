.pointValue{    
    height: 100vh;
    background : -moz-linear-gradient(0% 50% 0deg,rgba(255, 202, 0, 1) 0%,rgba(237, 113, 0, 1) 100%);
	background : -webkit-linear-gradient(0deg, rgba(255, 202, 0, 1) 0%, rgba(237, 113, 0, 1) 100%);
	background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(255, 202, 0, 1) ),color-stop(1,rgba(237, 113, 0, 1) ));
	background : -o-linear-gradient(0deg, rgba(255, 202, 0, 1) 0%, rgba(237, 113, 0, 1) 100%);
	background : -ms-linear-gradient(0deg, rgba(255, 202, 0, 1) 0%, rgba(237, 113, 0, 1) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFCA00', endColorstr='#ED7100' ,GradientType=0)";
	background : linear-gradient(90deg, rgba(255, 202, 0, 1) 0%, rgba(237, 113, 0, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFCA00',endColorstr='#ED7100' , GradientType=1);;
}

.point__title{
    color: white;
    text-align: center;
    margin: 20px;
    font-size: 1.5em;
    
}

.point__card input{
   max-width: 250px;
   text-align: center;
   margin:0 auto;
}

.point__card button{
	display: block;
	text-align: center;
	margin: 15px auto;
}

.form-label{
	display: block;
	text-align: center;
	font-weight: normal;
	font-size: 1.2em;
	margin-bottom: 15px!important;
}

.card{
	-webkit-box-shadow: 5px 5px 3px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 5px 5px 3px 0px rgba(0,0,0,0.5);
box-shadow: 5px 5px 3px 0px rgba(0,0,0,0.5);
}