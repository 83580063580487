.login__background {
    background: url(../../assets/images/Fondo-Login.jpg) no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    /* height:100vh; */
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 50px;
}

.fondo_blanco {
    background: #fff;
    border-radius: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.bgs {
    background-color: #ed7100 !important;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 35px;
    padding-right: 35px;
}

.btn-dark {
    margin-right: 20px;
    width: 200px;
    font-size: 20px;
    border-radius: 20px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #333 !important;
}

.image_usuario {
    margin-right: 20px;
    margin-left: 30px;
}

.border_tarjeta {
    border: 1px solid #ed7100 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 30px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}

@media(max-width: 767px) {
    .fondo_blanco{
        padding-left: 25px !important;
        padding-right: 25px !important;
    } 
    .border_tarjeta {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    .image_usuario{
        margin-left: auto;
    }
}

.titulo {
    font-weight: bold;
    margin-top: 10px;
}

.titulo2 {
    font-weight: bold;
    margin-top: 27px;
}

.lap {
    margin-top: 30px;
}